$card-primary-color: #f9f9f9;
$card-secondary-color: #767676;
$card-third-color: #333333;
$card-fourth-color: #edeff5;
$card-fifth-color: #21bf73;
$overlay-background: rgba(0, 0, 0, 0.5);
$header-primary-color: #333333;
$header-secondary-color: #bd2660;

.card {
  width: 100%;
  display: flex;
  height: 5rem;
  background-color: $card-primary-color;
  padding-right: 1rem;
  border-radius: 10px;

  &_image {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__logo {
      width: 3rem;
      height: 3rem;
    }
  }

  &_content {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid $card-fourth-color;

    &__upper {
      font-family: "Open Sans", sans-serif;
      font-size: 0.75rem;
      color: $card-secondary-color;
    }

    &__bottom {
      display: flex;
      justify-content: space-between;

      &:first-child {
        font-family: "Open Sans", sans-serif;
        font-size: 0.875rem;
        color: $card-third-color;
      }
    }

    &__cost {
      span:nth-child(1) {
        margin-right: 4px;
        font-size: 1rem;
      }
      span:nth-child(2) {
        font-size: 1rem;
        font-weight: bold;
      }

      span:nth-child(3) {
        font-size: 0.75rem;
        font-weight: bold;
        margin-right: 5px;
      }
    }
  }
}

.value_earn {
  color: $card-fifth-color;
}
