
/* Globle style for font to apply into every element*/
h1 { font-family: Open Sans, sans-serif; font-size: 24px; font-style: normal; font-variant: normal; font-weight: 700; line-height: 26.4px; } h3 { font-family: Open Sans, sans-serif; font-size: 14px; font-style: normal; font-variant: normal; font-weight: 700; line-height: 15.4px; } p { font-family: Open Sans, sans-serif; font-size: 14px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 20px; } blockquote { font-family: Open Sans, sans-serif; font-size: 21px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 30px; } pre { font-family: Open Sans, sans-serif; font-size: 13px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 18.5714px; }





.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-pxin infinite 20s linear;
  }
}

.App-container {
  background-color: #ffffff;
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  ::-webkit-scrollbar {display:none;}
}

.App-link {
  color: #ffffff;
}

@keyframes App-logo-pxin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* TAB CSS */
/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #fff;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ffffff;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  -webkit-animation: fadeEffect 1s;
  animation: fadeEffect 1s;
}

/* Fade in tabs */
@-webkit-keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ICONS */
.App-list-icon {
  height: 15vmin;
  pointer-events: none;
  margin-left: 10px;
}

.Dashboard-icon {
  height: 25vmin;
  pointer-events: none;
  margin-left: 10px;
}

.main-icon {
  margin-left: 0px!important;
}

.App-nav-icon {
  height: 8vmin;
  pointer-events: none;
}

/* custom css */
.font-size-10 {
  font: OpenSans-SemiBold, sans-serif !important;
  font-size: 10px !important;
}

.font-size-12 {
  font: OpenSans-SemiBold, sans-serif !important;
  font-size: 12px !important;
}

.font-size-14 {
  font: OpenSans-SemiBold, sans-serif !important;
  font-size: 14px !important;
}

.font-size-16 {
  font: OpenSans-SemiBold, sans-serif !important;
  font-size: 16px !important
}

.font-size-20 {
  font: OpenSans-SemiBold, sans-serif !important;
  font-size: 20px !important;
}

.font-size-32 {
  font: OpenSans-SemiBold, sans-serif!important;
  font-size: 32px !important;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.card {
  background: #fff;
  /*display: inline-block;*/
  /* height: 300px; */
  margin: 1rem;
  border: none !important;
  position: relative;
  /* width: 300px; */
}

.card-purple {
  background-image: linear-gradient(to right, rgb(118, 48, 151), rgb(179, 84, 206));
  /*display: inline-block;*/
  /* height: 300px; */
  margin: 1rem;
  border: none !important;
  position: relative;
  /* width: 300px; */
}

/* .flex-container {
  dipxlay: flex;
  flex-wrap: nowrap;
  background-color: inherit;
} */

.card-3 {
  box-shadow: 0 10px 20px #e0e8ea, 0 6px 6px #e0e8ea;
  padding: 20px;
  border-radius: 20px !important;
  /* width: 200px; */
  /* height: 150px; */
}

.card-4 {
  box-shadow: 0 10px 20px #c2c2c234, 0 6px 6px #c2c2c234;
  padding: 20px;
  border-radius: 20px !important;
}

.light-green-box {
  padding: 20px;
  background-color: #d1f4cb !important;
  border: none !important;
  border-radius: 10px !important;
  margin: 1rem;
  position: relative;
  /* width: 200px; */
  /* height: 150px; */
}

.card-3 .col-6 {
  margin-top: 8px;
}

.col-6 {
  margin-left: 10px;
}

.col-7 {
  margin-right: 10px;
}

.box {
  padding: 20px;
  border-radius: 1.25rem !important;
  background: #d8ecf9 !important;
  border: none !important;
  height: 50%;
  width: 80%;
}

.font-blue {
  color: #2598ed !important;
}
.row {
  padding: 20px !important;
}

.popUp {
  margin : 0;
  padding: 0px !important;
  margin-top: 5px;
  margin-bottom: -10px;
  margin-left: -5px!important;
  margin-right: -5px!important;
  transition: opacity .5s ease-in;
  transition: opacity .5s ease-out;
}

.popUpTextSmall{
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0;
  color: rgba(255, 255, 255, 0.87);
}

.errorModalMainText{
  color : black;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.36px;
  font-weight: bold;
}


.errorModalSubText{
  color : #333333;
  opacity: 0.7;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.06px;
  font-weight: bold;
}

.popUpTextMedium{
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  line-height: 40px;
  letter-spacing: 0.07px;
  float: right;
  color: #2598ed;
  background-color: rgba(0,0,0,0);

}

.popUpActionText{
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  line-height: 40px;
  letter-spacing: 0.07px;
  color: #2598ed;
  background-color: rgba(0,0,0,0);
  margin-top: 0.5em;
}

.ptr__children{
  transform:none!important;
}

.color-gray {
  color: #767676 !important;
}

.color-black {
  color: #333333 !important;
}

.color-blue {
  color: #2598ed !important;
}

.color-white {
  color: #FFFFFF !important;
}

.color-light-black {
  color: rgba(51, 51, 51, 0.8) !important;
}

.font-bold-1 {
  font-weight: 700 !important;
}

.font-bold-2 {
  font-weight: 600 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-regular {
  font-weight: 400 !important;
}

a:link {
  text-decoration: none !important;
}

a:visited {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

a:active {
  text-decoration: none !important;
}

.no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.no-margin {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.info-icon {
  width: 18px;
  height: 18px;
}

.arrow-icon {
  width: 25px;
  height: 25px;
}

.padding-left {
  padding-left: 15px;
}

.loading-gif {
  width: 3.5em;
  height: 3.5em;
}

.loading-gif-text {
  margin-top: 0.8em;
}

.remove-outline:focus{
  outline: none;
}

.hr-dashed {
  border-top: 1px dashed #ccc ;
}

.hr-white {
  background-color: #ffffff;
  border-width: 1px;
}

.vl {
  border-left: 1px solid #ccc;
}

.full-height {
  height: 100%;
  background: black;
}