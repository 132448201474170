.card {
  margin: 3%;
  width: 90%;
  display: block;
  padding: 0px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 10px 20px #e0e8ea, 0 6px 6px #e0e8ea;
 
  &_thumb {
    width: 100%;
    height: 170px;
    border-radius: 10px;
    background-color: #767676;
    object-fit:  cover;
  }

  &_content {
    width: 100%;
    padding: 16px ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: 600;
      color: #646464;
    }
  }
}

