
.flex-container {
  display:flex;
  flex-direction: column;
  padding: 2%;
  padding-left: '1%';
  padding-right: '1%';
  ::-webkit-scrollbar {display:none;}
}

.flex-item { 
  background-color: #ffffff;
  width: 100%;
  color: black;
  margin:2%;
  padding-right: 8%;
  text-align: center;  
}
  
.img-submit {
  margin-top:8%;
  margin-bottom: 8%;
  padding-right: 5%;
  text-align: center;
}

.listing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  ::-webkit-scrollbar {display:none;}
}
  
.no-data-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 2.5rem;
  align-items: center;
}
  
.empty-heading {
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
  color: #333333;
  line-height: 1.56rem;
  margin-top: 1.875rem;
  margin-right: -1.9rem;
}
  
.searchField {
  width: 90%;
  padding: 16px;
  text-align: center;
  height: 46px!important;
  border-radius: 28px;
  background-color: #000000;
  border:none;
  font-size: 14px;
  color: #777777;
}

.theme-construction {
  --radius: 5px;
  --baseFg: #4a4a4a;
  --baseBg: white;
  --accentFg: #4a4a4a;
  --accentBg:white;
}

select {
  font: 400 14px/1.3 sans-serif;
  -webkit-appearance: none;
  appearance: none;
  color: var(--baseFg);
  border: 1px solid var(--baseFg);
  line-height: 1;
  outline: 0;
  padding: 0.02em 3.0em 0.15em 0.35em;
  border-radius: var(--radius);
  background-color: var(--baseBg);
  background-image: linear-gradient(var(--baseFg), var(--baseFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentBg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentBg) 50%),
    linear-gradient(var(--accentBg) 35%, var(--accentFg) 35%);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-size: 1px 100%, 20px 22px, 20px 22px, 20px 100%;
  background-position: right 20px center, right bottom, right bottom, right bottom;   
}

//New additions

.searchBar {
  width: 328px;
  height: 328px;
  border-radius: 20;
  background-color: #f6f6f6;

}

.bottonLine {
  border-top: 1px solid green;
}

.avatar {
  vertical-align: middle;
  margin-top: -20px;
  margin-bottom: 20px;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.ListTags {
  padding-left: 16px;
  font-weight: bold;
  color: #767676;
  margin-top: 26px;
  font-size: 14px;
}

.searchMerchants {
  // width: 118;
  width: 80%;
  height: 23px;
  font-size: 14px;
  font-weight: "normal";
  font-style: "normal";
  line-height: 22px;
  letter-spacing: 0;
  color: #cccccc;
  text-align: center;
  margin-left: 3%;
  margin-top: 1%;
  margin-bottom: 1%;
}

.DonationAreas {
  font-size: 16px;
  color:black;
  height: 22px;
  white-space: nowrap;
}

.inputIcons i {
  position: absolute;
}

.inputIcons {
  height: 50px;
  font-size: 14px;
  font-weight: "normal";
  font-style: "normal";
  line-height: 22px;
  letter-spacing: 0;
  color: #cccccc;
  text-align: center;
  width: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  border-radius:8db;
  display: block;
  margin-bottom: 2%;
}

.icon {
  color: #474545;
  width: 18px;
  height: 18px;
  text-align: center;
  padding-left: 20px;
  padding-top:1%;
  padding-bottom:2%;
}

.inputField {
  width: 90%;
  padding: 8px;
  text-align: center;
  height: 46dp!important;
  border-radius: 8px 8px 8px 8px;
  background-color: #f6f6f6;
  border:none;
  font-size: 14px;
  color: #777777;
}

