$card-primary-color: #f9f9f9;
$card-secondary-color: #767676;
$card-third-color: #333333;
$card-fourth-color: #edeff5;
$card-fifth-color: #21bf73;
$overlay-background: rgba(0, 0, 0, 0.5);
$header-primary-color: #333333;
$header-secondary-color: #bd2660;

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(slide-up) {
  0% {
    top: 100%;
  }
  100% {
    top: 40%;
  }
}

@include keyframes(slide-up-overlay) {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}

.overlay {
  position: fixed;
  display: block;
  width: inherit;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $overlay-background;
  @include animation("slide-up-overlay 0.2s 1 normal forwards");

  &_card {
    height: 80%;
    width: inherit;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0px;
    border-radius: 20px 20px 0px 0px;
    background-color: $card-primary-color;
    @include animation("slide-up 0.2s 1 normal forwards");

    &_content {
      margin: 0 1rem 0 1rem;

      &_header {
        width: 100%;
        display: flex;
        align-items: center;
        height: 1.0rem;
        font-family: "Open Sans", sans-serif;
        font-size: 1.25rem;
        margin-top: 1.7rem;

        &_left {
          color: $header-primary-color;
          width: 88%;
          font-weight: bold;
        }

        &_right {
          width: 12%;
          color: $header-primary-color;
          text-align: right;
          margin-right: 0px;
          cursor: pointer;
        }
      }

      &_details {
        display: flex;
        width: 100%;
        margin-top: 1rem;

        &__Proprty {
          color: $card-secondary-color;
          margin-right: 4px;
          font-size: 1rem;
          width: 45%;
        }

        &__seperator {
          color: $card-secondary-color;
          font-size: 1rem;
          width: 10%;
          text-align: center;
        }

        &__value {
          color: $header-primary-color;
          text-align: right;
          display: flex;
          flex-direction: column;
          float: right;
          width: 45%;
          word-break: break-all;

          &_mobile {
            font-weight: bold;
          }

          &_amount {
            width: 45%;
            float: right;
            text-align: right;
            span:nth-child(2) {
              font-size: 1rem;
              font-weight: bold;
            }

            span:nth-child(3) {
              font-size: 0.75rem;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
