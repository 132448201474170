$header-primary-color: #333333;
$header-secondary-color: #bd2660;


.header {
    &_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3.5rem;
        position: fixed;
        top:0;
        z-index: 10000;
    }

    &_left {
        width: 15%;

        &__icon {
            display: block;
            margin: 0 auto;
        }
    }

    &_middle {
        display: flex;
        align-items: center;

        span {
            font-family: "Open Sans", sans-serif;
            font-size: 19px;
            font-weight: medium;

            &:first-child {
                margin-left: 0px;
                color: $header-primary-color;
            }
        }
    }

    &_right {
        width: 15%;

        &__icon {
            display: block;
            margin: 0 auto;
        }
    }
}

.messageTemplate {
    &_icon {
        display: block;
        margin: 0 auto;
    }
    &_iconwithmargin {
        margin-top: 3.5em;
        display: block;
    }
    &_margin{
        padding-left: 20px;
        padding-right: 20px;
    }
    &_headerMessage{
        color : black;
        font-size: 24px;
        font-weight: 600;
    }
    &_bodyMessage{
        color : #333333;
        font-size: 16px;
    }
    &_mainImage{
        object-fit: cover;
        width: 85%;
    }
    &_mainImageWithMargin{
        object-fit: cover;
        width: 85%;
        margin-top: 4.5em;
    }
}

.mainBtn{
    width: 100%;
    height : 3em;
    background-color: black;
    border-radius: 24px;
    color: white;
    font-weight: bold;
    font-size: 14px;
}

.mainBtn:focus{
    outline: none;
}
