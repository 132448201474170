.btn-transaction{
    color: white;
    background-color: rgb(53, 50, 50);
    cursor: pointer;
    display:block;
    margin-left: 3%;
    width: 90%;
    text-align: center;
    font-weight: bold;
    margin-top: 18px;
    margin-bottom: 18px;
    border-radius: 50px 50px 50px 50px;
    font-size: small;
    height: 50px;
    letter-spacing: 0.8px;
    vertical-align: bottom;
}

.btn-transaction:disabled {
    background: #e8ecf1;
}

.donationNumberField{
    font-size : '30px';
}

.donationNumberField::placeholder {
    color: #db1a1a;
    opacity: 1;
}

.popular-offer-container {
  width: 100%;
  height: 250px;
  flex-grow: 1;
  display:flex;
  margin: 8px;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {display:none;}
}

.popular-offer-card {
    width: 180px;
    height: 228px;
    margin: 12px;
    display: block;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 10px 20px #e0e8ea, 0 6px 6px #e0e8ea;
   
    &_thumb {
      width: 180px;
      height: 120px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #767676;
      object-fit: cover;
    }
  
    &_content {
      width: 100%;
      padding: 12px;
      display: block;
      flex-direction: column;
      justify-content: start;
  
      &__title {
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        word-wrap: break-word;
        overflow: hidden;
        height: 3.4em;
        max-height: 3.4em;
        line-height: 1.6em;
      }
  
      &__button {
        width: 85px;
        height: 24px;
        display: flex;
        margin-top: 12px;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        color: #ffffff;
        background-color: #9846b6;
        font-size: 12px;
        border-radius: 22px;
      }
  
    }
  }
  
  