.points-input{
    text-align: center;
    height: 32px;
    align-items: center;
    justify-content: center;
    border: none;
    /* border-bottom: 2px solid lightgrey; */
    border-color: transparent;
    margin-bottom: 10%;
    margin-top: 2%;
    font-weight: bold;
    font-size: 20px;
}

.error {
    color:#db2269;
    font-size: 0.625em;
    display: relative;
  }

textarea:focus, input:focus{
    outline: none;
}

.points-display{
   display: flex;
   justify-content: center;
   align-items: center;
}
/* @media all and (min-width:500px){ */
.btn-next{
    color: white;
    background-color: rgb(53, 50, 50);
    padding: 1%;
    cursor: pointer;
    width:100%;
    text-align: center;
    font-weight: bold;
    margin-top: 5%;
    margin-bottom: 5%;
    border-radius:50px 50px 50px 50px;
    font-size: 14px;
    height: 48px;
    /* letter-spacing: 0.8px; */
    vertical-align: bottom;
}
/* } */