// Bar Variables ========================================
$bar-size: 10px;
$bar-radius: 60px;

body {
	background-color: rgb(134, 134, 134);
	border-radius: $bar-radius;
}

// Bar ============================================
.progress-bar {
	border-radius: $bar-radius;
	overflow: hidden;
    width: 100%;

	span {
		display: block;
	}

}

.bar {
	background-color: rgba(0,0,0,0.075);
}

.progress {
  background-color: rgb(174, 220, 126);
  padding: $bar-size;
  width: 0;
}

// Vertical Centering ==============================
// You don't need this to make it work on your site.
// .progress-bar {
//   left: 50%;
//   max-width: 70%;
//   position: absolute;
//   top: 50%;
//   transform: translate3d(-50%,-50%,0);
// }



  