.otp-input {
    text-align: center;
    width: 90%;
    height: 48px;
    margin-top: 40px;
    margin-left: 5%;
    align-items: center;
    justify-content: center;
    border: none;
    border-color: transparent;
    background-color : #eeeeee; 
    font-weight: regular;
    font-size: 24px;
    letter-spacing: 8px;
    border-radius: 24px;
}

.resendButton{
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    line-height: 40px;
    letter-spacing: 0.07px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(0,0,0,0);
    margin-top: 50px;
  }