.btn-transaction{
    color: white;
    background-color: rgb(53, 50, 50);
    //padding: 3%;
    cursor: pointer;
    display:block;
    margin-left: 3%;
    //margin-right: 5%;
    width: 90%;
    text-align: center;
    font-weight: bold;
    margin-top: 18px;
    margin-bottom: 18px;
    border-radius: 50px 50px 50px 50px;
    font-size: small;
    height: 50px;
    letter-spacing: 0.8px;
    vertical-align: bottom;
}

.terms-conditions{
    padding-left: 1em;
    padding-right: 1em;
    text-align: justify;
}

.terms-conditions-heading {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: left;
    color: rgb(118, 118, 118);
}

ol {
    counter-reset: item;
}
li {
    display: block;
    padding-bottom: 0.4em;
}
li:before {
    content: counters(item, ".") " ";
    counter-increment: item
}

