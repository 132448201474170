.container {
  //height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_starpoint_container {
    //position: absolute;
    top: 56px;
    width: 100vw;
  }
}

.transactionEmptyIcon_container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 2.5rem;
  align-items: center;
}

.empty_heading {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  color: #333333;
  line-height: 1.56rem;
  margin-top: 1.875rem;
  margin-right: -1.9rem;
}
